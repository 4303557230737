import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "card d-flex flex-wrap flex-sm-nowrap mb-3" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title m-0" }
const _hoisted_4 = { class: "fw-boldest m-0" }
const _hoisted_5 = { class: "card-body p-9" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-md-6" }
const _hoisted_8 = { class: "form-group d-flex flex-wrap" }
const _hoisted_9 = { class: "col-form-label required fw-bold fs-6" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-md-6" }
const _hoisted_12 = { class: "form-group d-flex flex-wrap" }
const _hoisted_13 = { class: "col-form-label required fw-bold fs-6" }
const _hoisted_14 = { class: "col-md-6" }
const _hoisted_15 = { class: "form-group d-flex flex-wrap" }
const _hoisted_16 = { class: "col-form-label required fw-bold fs-6" }
const _hoisted_17 = { class: "row" }
const _hoisted_18 = { class: "form-group d-flex flex-wrap" }
const _hoisted_19 = { class: "col-form-label fw-bold fs-6" }
const _hoisted_20 = { class: "row" }
const _hoisted_21 = { class: "form-group d-flex flex-wrap" }
const _hoisted_22 = { class: "col-form-label fw-bold fs-6" }
const _hoisted_23 = { class: "card-footer d-flex justify-content-end py-6 px-9" }
const _hoisted_24 = {
  type: "submit",
  ref: "submitButton",
  class: "btn btn-primary"
}
const _hoisted_25 = { class: "indicator-label" }
const _hoisted_26 = { class: "card d-flex flex-wrap flex-sm-nowrap mb-3" }
const _hoisted_27 = { class: "card-header" }
const _hoisted_28 = { class: "card-title m-0" }
const _hoisted_29 = { class: "fw-boldest m-0" }
const _hoisted_30 = { class: "card-body p-9" }
const _hoisted_31 = { class: "card-footer d-flex justify-content-end py-6 px-9" }
const _hoisted_32 = { class: "indicator-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_tree = _resolveComponent("el-tree")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_form, {
      onSubmit: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.submit()), ["prevent"])),
      model: _ctx.formData,
      rules: _ctx.rules,
      ref: "formRef"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.t("permissions.information")), 1)
            ])
          ]),
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.t("common.status")), 1),
                  _createVNode(_component_el_form_item, { prop: "status" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_switch, {
                        modelValue: _ctx.formData.status,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.status) = $event)),
                        "active-value": 10,
                        "inactive-value": 25,
                        placeholder: ""
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.t("permissions.roleID")), 1),
                  _createVNode(_component_el_form_item, { prop: "id" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: _ctx.formData.id,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.id) = $event)),
                        type: "text",
                        placeholder: "",
                        disabled: ""
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.t("permissions.roleName")), 1),
                  _createVNode(_component_el_form_item, { prop: "name" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: _ctx.formData.name,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.name) = $event)),
                        type: "text",
                        placeholder: ""
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.t("permissions.note")), 1),
                _createVNode(_component_el_form_item, { prop: "note" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.formData.note,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.note) = $event)),
                      type: "textarea"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.t("permissions.applications")), 1),
                _createVNode(_component_el_form_item, { prop: "applications" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: _ctx.formData.applications,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.applications) = $event)),
                      clearable: "",
                      multiple: "",
                      placeholder: "Please Select",
                      style: {"width":"100%"}
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appOptions, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item.value,
                            label: item.label,
                            value: item.value
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ])
            ])
          ])), [
            [_directive_loading, _ctx.loading]
          ]),
          _createElementVNode("div", _hoisted_23, [
            _createVNode(_component_router_link, {
              to: '/permissions/role',
              class: "btn btn-secondary align-self-center me-2"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("common.backToList")), 1)
              ]),
              _: 1
            }),
            _createElementVNode("button", _hoisted_24, [
              _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.t("common.saveChanges")), 1)
            ], 512)
          ])
        ])
      ]),
      _: 1
    }, 8, ["model", "rules"]),
    _createElementVNode("div", _hoisted_26, [
      _createElementVNode("div", _hoisted_27, [
        _createElementVNode("div", _hoisted_28, [
          _createElementVNode("h3", _hoisted_29, _toDisplayString(_ctx.t("permissions.permissions")), 1)
        ])
      ]),
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_30, [
        _createVNode(_component_el_tree, {
          data: _ctx.permissionData,
          "show-checkbox": "",
          "node-key": "s_id",
          "current-node-key": _ctx.currentNodeKey,
          "default-expanded-keys": _ctx.defaultExpandedKeys,
          "default-checked-keys": _ctx.defaultCheckedKeys,
          onCheck: _ctx.handleCheck
        }, null, 8, ["data", "current-node-key", "default-expanded-keys", "default-checked-keys", "onCheck"])
      ])), [
        [_directive_loading, _ctx.loading]
      ]),
      _createElementVNode("div", _hoisted_31, [
        _createVNode(_component_router_link, {
          to: '/permissions/role',
          class: "btn btn-secondary align-self-center me-2"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("common.backToList")), 1)
          ]),
          _: 1
        }),
        _createElementVNode("button", {
          type: "submit",
          class: "btn btn-primary",
          onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleSavePermission && _ctx.handleSavePermission(...args)))
        }, [
          _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.t("common.saveChanges")), 1)
        ])
      ])
    ])
  ], 64))
}