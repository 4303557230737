
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ApiBase, ApiRole } from "@/core/api";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "role-detail",
  components: {},
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const loading = ref<boolean>(false);
    const permissionLoading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const deleteButton = ref<HTMLElement | null>(null);
    const permissionData = ref<any>([]);
    const currentNodeKey = ref<any>([]);
    const defaultExpandedKeys = ref<any>([]);
    const defaultCheckedKeys = ref<any>([]);
    const submitNodeKeys = ref<any>([]);
    const appOptions = ref<any>([]);

    const formData = ref({
      id: "",
      status: 10,
      name: "",
      sorting: "",
      note: "",
      applications: "",
    });

    const rules = ref({
      status: [
        {
          required: true,
          message: t("common.status") + t("common.isRequired"),
          trigger: "change",
        },
      ],
      name: [
        {
          required: true,
          message: t("permissions.roleName") + t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const getDetailData = () => {
      loading.value = true;
      ApiRole.getRoleInfo({ id: route.params.id })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            formData.value = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getPermissionData = () => {
      permissionLoading.value = true;
      ApiRole.getDataSourceRbacPermits()
        .then(({ data }) => {
          if (data.code == 0) {
            const treeData = data.data.tree;
            ApiRole.getRolePermission({ id: route.params.id })
              .then(({ data }) => {
                permissionLoading.value = false;
                if (data.code == 0) {
                  permissionData.value = treeData;
                  defaultCheckedKeys.value = formatPermissionRes(
                    data.data.permits
                  );
                  defaultExpandedKeys.value = formatPermissionRes(
                    data.data.permits
                  );
                  submitNodeKeys.value = data.data.permits;
                }
              })
              .catch((error) => {
                console.log(error);
              });
            defaultCheckedKeys.value = [9];
            defaultExpandedKeys.value = [9];
            submitNodeKeys.value = [9];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getAppOptions = async () => {
      const { data } = await ApiBase.getSsoAppsOptions({ is_options: 1 });
      if (data.code == 0) {
        appOptions.value = data.data;
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiRole.updateRole(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg();
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const deleteRole = () => {
      Swal.fire({
        text: t("common.deactivateRoleTips"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (deleteButton.value) {
            deleteButton.value.setAttribute("data-kt-indicator", "on");
          }
          loading.value = true;
          ApiRole.deleteRole({ id: route.params.id })
            .then(({ data }) => {
              loading.value = false;
              deleteButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                Swal.fire({
                  text: t("common.roleDeactivatedTips"),
                  icon: "success",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.push({ name: "role" });
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    };

    const handleSavePermission = () => {
      ApiRole.saveRolePermission({
        id: route.params.id,
        permits: submitNodeKeys.value,
      })
        .then(({ data }) => {
          if (data.code == 0) {
            showFormSubmitSuccessMsg();
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleCheck = (
      data,
      { checkedNodes, checkedKeys, halfCheckedNodes, halfCheckedKeys }
    ) => {
      submitNodeKeys.value = formatPermissionReq(checkedKeys);
    };

    const formatPermissionReq = (selectedKeys) => {
      return selectedKeys
        .filter((item) => item.indexOf("p-") !== -1)
        .map((item) => item.slice(2));
    };

    const formatPermissionRes = (selectedKeys) => {
      return selectedKeys.map((item) => "p-" + item);
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs(t("permissions.information"), [
        { name: t("permissions.permissions"), path: "" },
        { name: t("permissions.roleManagement"), path: "/permissions/role" },
      ]);
      getDetailData();
      getPermissionData();
      getAppOptions();
    });

    return {
      t,
      loading,
      permissionLoading,
      formRef,
      formData,
      rules,
      permissionData,
      appOptions,
      currentNodeKey,
      defaultExpandedKeys,
      defaultCheckedKeys,
      submit,
      deleteRole,
      handleSavePermission,
      handleCheck,
    };
  },
});
